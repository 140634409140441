import './styles.scss';
import { useTranslation } from 'react-i18next';
export type FrameworkDatapoint = {
  id: string;
  name_dcycle: string;
  datapoint_id: string;
  esg_category: string;
  name_en: string;
  name_es: string;
  name_pt: string;
};
export type FrameworkDatapointsProps = {
  datapoints: FrameworkDatapoint[];
  boxColor: string;
  dotColor: string;
  datapointBorderColor: string;
  title: string;
  total: number;
};
const DatapointListRenderer = ({
  datapoints,
  boxColor,
  dotColor,
  datapointBorderColor,
  title,
  total
}: FrameworkDatapointsProps) => {
  const { i18n } = useTranslation();
  const getTranslatedName = (datapoint: FrameworkDatapoint) => {
    const currentLanguage = i18n.language;

    switch (currentLanguage) {
      case 'es':
        return datapoint.name_es;
      case 'pt':
        return datapoint.name_pt;
      default:
        return datapoint.name_en;
    }
  };
  return (
    <div
      style={{
        backgroundColor: boxColor,
        width: '33%',
        position: 'relative',
        height: 'min-content'
      }}
      className='rounded-8 flex-col'>
      <div className='flex items-center justify-between p-4'>
        <div className='flex items-center font-12 semibold-font gap-2'>
          <div style={{ backgroundColor: dotColor }} className='rounded-20 w-2 h-2'></div>
          {title}
        </div>
        <span className='font-14 semibold-font' style={{ color: dotColor }}>
          {total}
        </span>
      </div>
      {total > 0 && (
        <div className='flex-col gap-4 p-4'>
          {datapoints.map((datapoint) => (
            <div
              key={datapoint.id}
              className='rounded-8 p-4 border-1 flex-col gap-2'
              style={{ borderColor: datapointBorderColor, backgroundColor: '#ffffff' }}>
              <span style={{ color: dotColor }}>{datapoint.datapoint_id}</span>
              {getTranslatedName(datapoint)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DatapointListRenderer;
